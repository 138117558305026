// JQUERY
import $ from "jquery";

// let firstItem = $(".middle-list-item:first-child");

// firstItem.addClass("active");

// $(".middle-list-item:not(:first-child)").on("hover", () => {
//   firstItem.removeClass("active");
// });

// $(".menu-level-1 .menu-item:not(:first-child)").on("mouseleave", (e) => {
//   e.preventDefault();

//   firstItem.addClass("show");
// });

// adding active class for each item
$(".middle-list-item").on("mouseover", function (e) {
  e.preventDefault();

  $(this).addClass("active");
});

$(".middle-list-item").on("mouseleave", function (e) {
  e.preventDefault();

  if ($(this).hasClass("active")) {
    $(this).removeClass("active");
  }
});

// adding active to first item on hover of parent
$(".top-list-item").on("mouseover", function (e) {
  e.preventDefault();

  let items = $(this).children().find(".middle-list-item");

  let firstItem = items.first();

  // checking if any of the other items have active class
  if (!firstItem.hasClass("active") && !items.hasClass("active")) {
    firstItem.addClass("active");
  }
});
$(".top-list-item").on("mouseleave", function (e) {
  e.preventDefault();
  let firstItem = $(this).children().find(".middle-list-item").first();

  if (firstItem.hasClass("active")) {
    firstItem.removeClass("active");
  }
});
