const scrollOffset = 100;

const scrollElements = document.querySelectorAll(".js-scroll");

// accessibility
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

const elementInView = (element, offset = 0) => {
  const elementTop = element.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) - offset
  );
};

const displayScrollElement = (element) => {
  element.classList.add("scrolled");
};

const hideScrollElement = (element) => {
  if (!element.classList.contains("scroll-once")) {
    element.classList.remove("scrolled");
  }
  if (
    !element.classList.contains("scrolled") &&
    mediaQuery &&
    !mediaQuery.matches
  ) {
    element.style.opacity = "0";
  }
};

const handleScrollAnim = () => {
  scrollElements.forEach((el) => {
    if (elementInView(el, scrollOffset)) {
      displayScrollElement(el);
    } else {
      hideScrollElement(el);
    }
  });
};

// throttle function for performance

let throttleTimer = false;
const throttle = (callback, time) => {
  if (throttleTimer) return;

  throttleTimer = true;

  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

window.addEventListener("scroll", () => {
  if (mediaQuery && !mediaQuery.matches) {
    throttle(handleScrollAnim, 250);
  }
});

// call scroll animation function on load
handleScrollAnim();
