// JQUERY
import $ from "jquery";

const $chevron = $("#return-arrow-wrap");

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

$chevron.on("click", scrollToTop);
