// JQUERY
import $ from "jquery";

const $downArrow = $("#down-arrow");
const $scrollComponent = $(".scroll-to")[0];

const scrollDown = () => {
  if ($scrollComponent.getClientRects().length > 0) {
    window.scrollTo({
      top: $scrollComponent.offsetTop + $($scrollComponent).outerHeight(),
      behavior: "smooth",
    });
  }
};

$downArrow.on("click", scrollDown);
